.label {
    font-weight: bold;
    text-decoration: underline;
}
.header-color {
    background: #000046; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}

.landing-grid {
    background: #000046; /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #1CB5E0, #000046); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #1CB5E0, #000046); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    text-align: center;
    width: 100%;
    height: 100%;
    position: fixed;
}



.avatar-img {
    height: 250px;
    padding-top: 5em;
}

.banner-text {
    background-color: black;
    opacity: .8;
    width: 75%;
    border-radius: 10px;
    margin: auto;
    margin-top: 4px;
}

    .banner-text h1 {
        font-size: 66px;
        font-weight: bold;
        color: white;
    }

    .banner-text hr {
        border-top: 5px dotted white;
        width: 50%;
        margin: auto;
    }

    .banner-text p {
        font-size: 25px;
        padding: 1em;
        color: white;
    }

.social-links {
    justify-content: space-between;
    width:100%;
    margin: auto;
}

    .social-links i {
        color: white;
        font-size: 5em;
        padding: 5px;
    }

.projects-grid {
    display: flex;
}

.contact-body {
    margin: auto;
    justify-content: center;
    padding-top: 2em;
    width: 100%;
    height: 100%
}

.contact-grid {
    text-align: center;
    justify-content: center;
    width: 80%;
    height: 550px;
    background: white;
    color: black;
    margin-bottom: 20px;
}
.ninja {
    padding-right: 150px; 
}

.icon-image {
    max-width: 100px;
    max-height: 70px;
}
    .contact-grid h2 {
        font-family: 'Anton', sans-serif;
    }

    .contact-grid p {
        font-family: 'oxygen', sans-serif;
        font-weight: bold;
    }

    .contact-grid hr {
        border-top:5px dotted black;
        width: 50%;
        margin: auto;
    }

.contact-list {
    margin: auto;
    justify-content: center;
    padding-top: 2em;
    width: 100%;
    height: 100%
}
.contact-list i {
    font-size: 1em;
    padding-right: 5rem;
}

.contact-list img {
    height: 100px;
    margin:-15px;
    padding-right: 50px;
}

.resume-right-col{
    background: #27221F;
    color: white;
}
a, a:link, a:visited, a:hover, a:active {
    color: #1CB5E0
}
@media (max-width: 750px){
    .landing-grid {
        position: relative;
    }
}
.blog-title {
    color: blue;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 20px;
}

.blog-title-pic {
    max-width: 500px;
    max-height: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;               
    
}
.page-title {
    text-align: center;
    color:black;
    font-family: 'Oswald';
}
.code-text {
    background: rgba(218, 212, 212, 0.952);
    color: black;
    white-space: pre-wrap;
}